import styled, { keyframes } from "styled-components";

export const ProceedButtonContainer = styled.div`
  display: flex;
  padding: 32px;
  width: 30%;
  background-color: white;
`;

export const ViewFileButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const SearchUploadContainer = styled.div`
  margin: 72px 0;
`;

export const FileUploadSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 15px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
  background-color: white;
  height: 100%;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

export const StageNumber = styled.span`
  color: white;
  font-weight: 600;
  font-size: 20px;
`;

export const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0;
`;

export const UploadTextHeader = styled.p`
  font-size: 22px;
  font-weight: 600;
  margin: 0;
`;

export const StageLine = styled.div`
  height: 4px;
  width: 100%;
  background-color: ${(props) => props.background};
  align-self: center;
  margin: auto;
  opacity: 50%;
`;

export const StageItem = styled.div`
  background-color: ${(props) => props.background};
  height: 52px;
  min-width: 52px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: white;
  flex-grow: 1;
`;

export const StyledTr = styled.tr`
  border: ${(props) => (props.selected ? "2px solid orange" : "none")};
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "white" : "#EBE9F1BF")};
`;

export const UploadTextSubheader = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #888888;
`;

export const UploadButton = styled.button`
  color: ${(props) => (props.disabled ? "grey" : "#ff6d1d")};
  background: none;
  border: none;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  font-size: 18px;
  font-weight: 600;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const UploadContainer = styled.div`
  border: 1px dashed;
  border-color: ${(props) => (props.disabled ? "grey" : "#ff6d1d")};
  background-color: white;
  height: 252px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
`;
