import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import AnalyzedSubmissions from "../pages/buyers/analyzedPage";
import BidMatching from "../pages/bidMatching";
import IDPDocuments from "pages/underwriterDashboard/idpDocuments";
import Uploader from "pages/underwriterDashboard/idpDocuments/uploader";
import Splitter from "pages/underwriterDashboard/idpDocuments/splitter";
import Classifier from "pages/underwriterDashboard/idpDocuments/classifier";
import ParserIDP from "pages/underwriterDashboard/idpDocuments/parser";
import DocumentViewer from "pages/underwriterDashboard/idpDocuments/documentViewer";
import BuyersAllProjects from "../pages/buyers/allProjectsPage";
import CriteriaPage from "../pages/buyers/criteriaPage";
import Dashboard from "../pages/dashboard/index";
import DashHeader from "../components/dashboardHeader";
import LoginPage from "../pages/loginPage";
import NewPreQualify from "../pages/newPreQualify";
import News from "../pages/news";
import NotFound from "../pages/notFound";
import PreQualify from "../pages/preQualifyForm";
import ProfileSetup from "../pages/contractorDashboard/profileSetup";
import ProjectSetup from "../pages/buyers/projectSetup/index.js";
import ProjectSubmissions from "../pages/buyers/submissionsPage";
import Qualification from "../pages/qualification";
import QuestionnaireScreen from "../pages/referenceModule/questionnaireScreen";
import ReferenceGiverScreen from "../pages/referenceModule/referenceGiverScreen";
import ReferenceModule from "../pages/referenceModule";
import ReleaseNotes from "../pages/releaseNotes";
import ResetPasswordPage from "../pages/userOnBoardingPage/resetPasswordPage";
import Rfpq from "../pages/rfpq";
import SeeAllCQS from "../pages/contractorDashboard/detailScreens/SeeAllCQS";
import SeeAllPersonnel from "../pages/contractorDashboard/detailScreens/SeeAllPersonnel";
import SeeAllPrequalifiedContractors from "../pages/buyerDashboard/detailScreens/SeeAllPrequalifiedContractors";
import SeeAllProjects from "../pages/contractorDashboard/detailScreens/SeeAllProjects";
import StaffLoginPage from "../pages/staffLoginPage";
import SubmissionDetails from "../pages/buyers/submissionDetailsPage";
import SubscribePage from "../pages/subscribePage";
import SystemMessages from "../components/systemMessages";
import Test from "../pages/test";
// import UserManagement from "../pages/rbacPages/userManagement/index.jsx";
import AdminTools from "../pages/adminToolsDashboard/adminToolsSetup/index.js";
import AccountInsights from "pages/underwriterDashboard/accountInsights/index.js";
import PortfolioInsights from "pages/underwriterDashboard/portfolioInsights/index.js";
import ChangePassword from "../pages/contractorDashboard/profileSetup/ChangePassword.js";
import ContractorDashboard from "../pages/contractorDashboard/index.js";
import BuyerDashboard from "../pages/buyerDashboard/index";
import UnderwriterDashboard from "../pages/underwriterDashboard/index";
import UserOnBoardingPage from "../pages/userOnBoardingPage";
import VerificationPage from "../pages/userOnBoardingPage/verificationPage";
import ContractStatusReportIssuing from "../pages/underwriters/components/contractStatusReportIssuing/index.js";
import PaymentProgressCertificateForApproval from "../pages/workInProgress/components/paymentProgressCertificateForApproval/index.js";
import ChangeOrderForApproval from "../pages/workInProgress/components/changeOrderForApproval/index.js";
import ConfirmNewUserFromResearchDatabase from "../pages/loginPage/ConfirmNewUserFromResearchDatabase";
import ContractStatusReportSending from "../pages/underwriters/components/contractStatusReportSending/index";
import ContractStatusReportViewing from "../pages/underwriters/components/contractStatusReportViewing/index";
import ContractStatusReportViewingWithToken from "../pages/underwriters/components/contractStatusReportViewingWithToken/index.js";
import DocumentPool from "../pages/documentPool";
import AllClientPage from "../pages/underwriters/allClientPage";
import AllProjectPage from "pages/underwriters/allProjectPage";
import PeerGroupAnalysis from "pages/underwriters/components/peerGroupAnalysis/index.js";
import FinancialAnalysis from "pages/underwriters/components/financialAnalysis/index.js";
import WorkInProgress from "pages/workInProgress";
import WipForProject from "pages/workInProgress/components/wipForProject";
import ServiceProviderDashboard from "pages/serviceProviderDashboard";
import AllServiceProviderClientPage from "pages/serviceProviders/allClientPage";
import AllRfpqsPage from "pages/serviceProviders/allRfpqsPage";
import ContractorAgentDashboard from "pages/serviceProviderDashboard/contractorAgentDashboard";
import BuyerAgentDashboard from "pages/serviceProviderDashboard/buyerAgentDashboard";
import SeeAllContractorCqs from "pages/serviceProviderDashboard/sideSection/SeeAllContractorCqs";
import ViewAllPpcPage from "pages/serviceProviders/ViewAllPpcPage";
import ViewAllCoPage from "pages/serviceProviders/ViewAllCoPage";
import ClientAlertsPage from "pages/serviceProviders/clientAlertsPage";

function WebContainer() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        position: "relative",
        backgroundColor: "#F9FBFC",
      }}
    >
      <Route path="/toolbar" component={DashHeader} />
      <Route path="/buyers" component={DashHeader} />
      <Route path="/underwriter" component={DashHeader} />
      <Route path="/service_provider" component={DashHeader} />
      <Route path="/prequalify" component={DashHeader} />
      <Route path="/onboarding" component={DashHeader} />
      <Route path="/verification" component={DashHeader} />
      <Route path="/reset" component={DashHeader} />
      <Route path="/all" component={DashHeader} />
      <Route path="/classic" component={DashHeader} />
      <Route path="/profile" component={DashHeader} />
      <Route path="/reference" component={DashHeader} />
      <Route path="/documents" component={DashHeader} />
      <Route path="/release_notes" component={DashHeader} />
      <Route path="/rfpq" component={DashHeader} />
      <Route path="/subscribe" component={DashHeader} />
      <Route path="/user-management" component={DashHeader} />
      <Route path="/admin-tools" component={DashHeader} />
      <Route path="/change-password" component={DashHeader} />
      <Route path="/contractor-dashboard" component={DashHeader} />
      <Route path="/buyer-dashboard" component={DashHeader} />
      <Route path="/underwriter-dashboard" component={DashHeader} />
      <Route path="/client" component={DashHeader} />
      <Route path="/service-provider-dashboard" component={DashHeader} />

      <Route path="/contractor-dashboard" component={SystemMessages} />
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/toolbar" />} />
        {/*<Route exact path='/' component={Dashboard} /> */}
        {/* TODO: Temporary fix to route from website /login */}
        <Route
          exact
          path="/login"
          render={() => <Redirect to="/account/login" />}
        />
        <Route path="/account" component={LoginPage} />
        <Route path="/staff/account" component={StaffLoginPage} />
        <Route
          path="/reference/given/:token"
          component={ReferenceGiverScreen}
        />
        <Route
          path="/contract/status/report/:token"
          component={ContractStatusReportIssuing}
        />
        <Route
          path="/wip/ppc/approval/:token1/:token2"
          component={PaymentProgressCertificateForApproval}
        />
        <Route
          path="/wip/changeorder/approval/:token1/:token2"
          component={ChangeOrderForApproval}
        />
        <Route
          path="/register/confirm/:token1/:token2"
          component={ConfirmNewUserFromResearchDatabase}
        />
        <Route
          path="/underwriter/csr/view/:token"
          component={ContractStatusReportViewingWithToken}
        />

        <PrivateRoute
          path="/service-provider-dashboard/contractor-client"
          component={ContractorAgentDashboard}
        />
        <PrivateRoute
          path="/service-provider-dashboard/buyer-client"
          component={BuyerAgentDashboard}
        />
        <PrivateRoute path="/client/alerts/:id" component={ClientAlertsPage} />
        <PrivateRoute
          path="/service-provider-dashboard/allPPCs"
          component={ViewAllPpcPage}
        />
        <PrivateRoute
          path="/service-provider-dashboard/allChangeOrders"
          component={ViewAllCoPage}
        />
        <PrivateRoute
          path="/service-provider-dashboard/clients/cqs"
          component={SeeAllContractorCqs}
        />
        <PrivateRoute exact path="/all/personnel" component={SeeAllPersonnel} />
        <PrivateRoute exact path="/all/projects" component={SeeAllProjects} />
        <PrivateRoute exact path="/all/CQS" component={SeeAllCQS} />
        <PrivateRoute exact path="/bid" component={BidMatching} />
        <PrivateRoute
          exact
          path="/buyers/projects"
          component={BuyersAllProjects}
        />
        <PrivateRoute
          exact
          path="/underwriter/sending/contract/status/request"
          component={ContractStatusReportSending}
        />
        <PrivateRoute
          exact
          path="/underwriter/viewing/contract/status/report"
          component={ContractStatusReportViewing}
        />

        <PrivateRoute
          exact
          path="/underwriter/clients"
          component={AllClientPage}
        />
        <PrivateRoute
          exact
          path="/service_provider/clients"
          component={AllServiceProviderClientPage}
        />
        <PrivateRoute
          exact
          path="/service_provider/projects"
          component={AllRfpqsPage}
        />
        <PrivateRoute
          exact
          path="/underwriter/client/projects"
          component={AllProjectPage}
        />
        <PrivateRoute
          exact
          path="/underwriter/client/peergroupanalysis"
          component={PeerGroupAnalysis}
        />
        <PrivateRoute
          exact
          path="/underwriter/client/financialanalysis"
          component={FinancialAnalysis}
        />
        <PrivateRoute
          exact
          path="/underwriter/accountinsights"
          component={AccountInsights}
        />
        <PrivateRoute
          exact
          path="/underwriter/portfolioinsights"
          component={PortfolioInsights}
        />
        <PrivateRoute exact path="/client/wip" component={WorkInProgress} />
        <PrivateRoute
          exact
          path="/underwriter/client/wip"
          component={WorkInProgress}
        />
        <PrivateRoute
          exact
          path="/underwriter/client/wip/project"
          component={WipForProject}
        />
        <PrivateRoute
          exact
          path="/client/wip/project"
          component={WipForProject}
        />
        <PrivateRoute
          exact
          path="/buyers/projects/setup"
          component={CriteriaPage}
        />
        <PrivateRoute
          exact
          path="/buyers/submissions/:id"
          component={ProjectSubmissions}
        />
        <PrivateRoute
          exact
          path="/buyers/submissions/:id/analyzed"
          component={AnalyzedSubmissions}
        />
        <PrivateRoute
          exact
          path="/buyers/submissions/:id/:submissionId"
          component={SubmissionDetails}
        />
        <PrivateRoute
          exact
          path="/buyers/prequalified/contractors"
          component={SeeAllPrequalifiedContractors}
        />
        <PrivateRoute path="/buyers" component={ProjectSetup} />
        <PrivateRoute exact path="/classic" component={PreQualify} />
        <PrivateRoute exact path="/toolbar" component={Dashboard} />
        <PrivateRoute
          exact
          path="/onboarding"
          component={UserOnBoardingPage}
          type="LOGGED_IN"
        />
        <PrivateRoute exact path="/news" component={News} />
        <PrivateRoute path="/prequalify" component={NewPreQualify} />
        <PrivateRoute exact path="/profile" component={ProfileSetup} />
        <PrivateRoute exact path="/qualification" component={Qualification} />
        <PrivateRoute exact path="/reference" component={ReferenceModule} />
        <PrivateRoute exact path="/documents" component={DocumentPool} />
        <PrivateRoute
          exact
          path="/reference/provide/:token"
          component={QuestionnaireScreen}
          type="REFERENCE"
        />

        <PrivateRoute
          exact
          path="/release_notes"
          component={ReleaseNotes}
          type="ALL_USERS"
        />
        <PrivateRoute
          exact
          path="/reset/:token"
          component={ResetPasswordPage}
          type="VERIFY"
        />
        <PrivateRoute path="/rfpq" component={Rfpq} />
        <Route path="/subscribe" component={SubscribePage} />
        {/* <PrivateRoute
          exact
          path="/user-management"
          component={UserManagement}
        /> */}
        <PrivateRoute exact path="/admin-tools" component={AdminTools} />
        {/* <PrivateRoute exact path="/account-insights" component={AccountInsights} /> */}
        <PrivateRoute
          exact
          path="/change-password"
          component={ChangePassword}
        />
        <PrivateRoute
          exact
          path="/contractor-dashboard"
          component={ContractorDashboard}
        />
        <PrivateRoute
          exact
          path="/buyer-dashboard"
          component={BuyerDashboard}
        />
        <PrivateRoute
          exact
          path="/underwriter-dashboard"
          component={UnderwriterDashboard}
        />
        <PrivateRoute
          exact
          path="/underwriter/idpdocuments"
          component={IDPDocuments}
        />
        <PrivateRoute
          exact
          path="/underwriter/idpdocuments/uploader"
          component={Uploader}
        />
        <PrivateRoute
          exact
          path="/underwriter/idpdocuments/splitter"
          component={Splitter}
        />
        <PrivateRoute
          exact
          path="/underwriter/idpdocuments/classifier"
          component={Classifier}
        />
        <PrivateRoute
          exact
          path="/underwriter/idpdocuments/parser"
          component={ParserIDP}
        />
        <PrivateRoute
          exact
          path="/underwriter/idpdocuments/documentViewer"
          component={DocumentViewer}
        />
        <PrivateRoute
          exact
          path="/service-provider-dashboard"
          component={ServiceProviderDashboard}
        />
        <PrivateRoute
          exact
          path="/verification/:token"
          component={VerificationPage}
          type="VERIFY"
        />
        {/* <PrivateRoute exact path="/test" component={Test} /> */}
        <Route component={NotFound} />
      </Switch>
    </div>
  );
}

export default WebContainer;
