import { ScreenRoot } from "../portfolioInsights/styles";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  DeleteButton,
  FunctionButtonContainer,
  HeaderContainer,
  StatusSpan,
  StyledButton,
  StyledHeader,
  StyledTable,
  StyledTd,
  TabButton,
  TabButtonSpan,
  TabContainer,
  VerticalContainer,
} from "./styles";
import { LargeText, MediumText } from "../styles";
import {
  getUnderwriterIdpActiveDocuments,
  getUnderwriterIdpArchivedDocuments,
} from "../../../data/actions/underwriters";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ViewIcon } from "../../../assets/common/ViewIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/common/deleteIcon.svg";
import {
  underwriterIdpActiveDocumentsSelector,
  underwriterIdpArchivedDocumentsSelector,
} from "../../../data/selectors/underwriters";
import { useHistory } from "react-router";
import {
  deleteFileFromIdpClientDocuments,
  getPdfDocsFromBucket,
} from "api/external_data.js";
import { ViewFileButton } from "./uploader/styles";
import { Popup } from "../../../components/popup";
import { CloseButton } from "../../../components/closeButton";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";

export default function IDPDocuments() {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const dispatch = useDispatch();
  let history = useHistory();

  const [selectedTab, setSelectedTab] = useState("pending");
  const [activeDocuments, setActiveDocuments] = useState([]);
  const [archivedDocuments, setArchivedDocuments] = useState([]);
  const [pdf, setPdf] = useState({
    fileName: "",
    data: null,
  });

  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [query, setQuery] = useState();

  const underwriterIdpActiveDocumentsData = useSelector(
    underwriterIdpActiveDocumentsSelector,
    (prev, next) => prev.length === next.length,
  );
  const underwriterIdpArchivedDocumentsData = useSelector(
    underwriterIdpArchivedDocumentsSelector,
    (prev, next) => prev.length === next.length,
  );

  useEffect(() => {
    if (underwriterIdpActiveDocumentsData.length < 1) {
      dispatch(getUnderwriterIdpActiveDocuments());
    }
    if (underwriterIdpArchivedDocumentsData.length < 1) {
      dispatch(getUnderwriterIdpArchivedDocuments());
    }
    if (underwriterIdpActiveDocumentsData) {
      underwriterIdpActiveDocumentsData.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at),
      );
      setActiveDocuments(underwriterIdpActiveDocumentsData);
      // getDocuments(underwriterIdpActiveDocumentsData);
    }
    if (underwriterIdpArchivedDocumentsData) {
      underwriterIdpArchivedDocumentsData.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at),
      );
      setArchivedDocuments(underwriterIdpArchivedDocumentsData);
    }
  }, [underwriterIdpActiveDocumentsData, underwriterIdpArchivedDocumentsData]);

  const handleProceed = () => {
    history.push("/underwriter/idpdocuments/uploader");
  };

  const getDocuments = (data) => {
    let bucketNames = [];
    let filePath = [];
    data.forEach((activeDoc) => {
      const parsedFileInfo = JSON.parse(activeDoc.file_info);

      const parts = parsedFileInfo.fileLocation.split("/");
      const firstElement = parts.slice(0, 2).join("/");

      filePath.push(firstElement);
      bucketNames.push(parsedFileInfo.bucketName);
    });

    bucketNames = [...new Set(bucketNames)];
    filePath = [...new Set(filePath)];
  };

  const handleViewFile = async (data, fileName) => {
    console.log("data ", data);

    setOpenPdfViewer(true);

    const pdfBlobs = await getPdfDocsFromBucket(data);

    console.log("pdf blob ", pdfBlobs);

    const pdf = pdfBlobs.find((pdf) => pdf.fileName === fileName);

    if (pdf !== -1) {
      const pdfUrl = URL.createObjectURL(
        new Blob([pdf.blob], { type: "application/pdf" }),
      );

      setPdf({
        data: pdfUrl,
        fileName,
      });
    } else {
      console.log(
        "couldn't load the PDF because there wasn't a matching one in the bucket. ",
      );
    }
  };

  const getBeforeSecondSlash = (input) => {
    const firstSlashIndex = input.indexOf("/");
    const secondSlashIndex = input.indexOf("/", firstSlashIndex + 1);

    return secondSlashIndex !== -1
      ? input.substring(0, secondSlashIndex)
      : input;
  };

  const deleteDocument = async (fileInfo, fileLocation, accountId) => {
    const deleteData = {
      account_id: accountId,
      delete_file_location: fileLocation,
      file_info_array: fileInfo,
    };
    await deleteFileFromIdpClientDocuments(
      deleteData,
      (response) => {
        console.log("successfully deleted the file");
      },
      (error) => {
        console.log("there was an error deleting the file");
      },
    );
  };

  return (
    <>
      <ScreenRoot isMobile={isMobile}>
        <VerticalContainer>
          <HeaderContainer>
            <div>
              <LargeText>Document List</LargeText>
            </div>
            <SearchBar
              filteredData={
                selectedTab === "pending"
                  ? underwriterIdpActiveDocumentsData
                  : underwriterIdpArchivedDocumentsData
              }
              setInternalSearchResults={
                selectedTab === "pending"
                  ? setActiveDocuments
                  : setArchivedDocuments
              }
              query={query}
              setQuery={setQuery}
              internalSearch={true}
              searchWidth="50%"
              searchPlaceHolder="Search by Account ID, Account Name, Document Name, Date Uploaded and IDP Status"
            />
            <StyledButton onClick={handleProceed}>Upload New</StyledButton>
          </HeaderContainer>
          <TabContainer>
            <TabButton
              active={selectedTab === "pending"}
              onClick={() => setSelectedTab("pending")}
            >
              Pending
              <TabButtonSpan> ({activeDocuments.length})</TabButtonSpan>
            </TabButton>
            <TabButton
              active={selectedTab === "complete"}
              onClick={() => setSelectedTab("complete")}
            >
              Complete
              <TabButtonSpan> ({archivedDocuments.length})</TabButtonSpan>
            </TabButton>
          </TabContainer>
          <StyledTable>
            <tr>
              <StyledHeader>Account ID</StyledHeader>
              <StyledHeader>Account Name</StyledHeader>
              <StyledHeader>Document Name</StyledHeader>
              <StyledHeader>Date Uploaded</StyledHeader>
              <StyledHeader>IDP Status</StyledHeader>
            </tr>
            {selectedTab === "pending" &&
              activeDocuments.map(
                ({
                  account_id,
                  account_name,
                  file_info,
                  created_at,
                  idp_status,
                  client_documents_id,
                  classifier_threshold,
                }) => {
                  // Parse file_info since it's a JSON string
                  const parsedFileInfo = JSON.parse(file_info);

                  return parsedFileInfo.map((file) => (
                    <tr
                      key={`${account_id}-${file.fileId}`}
                      onClick={() => {
                        if (idp_status === "Splitter") {
                          history.push({
                            pathname: "/underwriter/idpdocuments/splitter",
                            state: {
                              selectedAccount: {
                                AccountId: account_id,
                                CompanyName: account_name,
                              },
                              clientDocumentsId: client_documents_id,
                              filePath: getBeforeSecondSlash(file.fileLocation),
                              bucketName: file.bucketName,
                            },
                          });
                        } else if (idp_status === "Classifier") {
                          history.push({
                            pathname: "/underwriter/idpdocuments/classifier",
                            state: {
                              selectedAccount: {
                                AccountId: account_id,
                                CompanyName: account_name,
                                ClassifierThreshold: classifier_threshold,
                              },
                              clientDocumentsId: client_documents_id,
                            },
                          });
                        } else if (idp_status === "Parser") {
                        }
                      }}
                    >
                      <StyledTd>{account_id}</StyledTd>
                      <StyledTd>{account_name}</StyledTd>
                      <StyledTd>{file.fileName}</StyledTd>
                      <StyledTd>
                        {new Date(created_at).toLocaleString()}
                      </StyledTd>
                      <StyledTd>
                        <StatusSpan status={idp_status}>
                          {idp_status}
                        </StatusSpan>
                      </StyledTd>
                      <FunctionButtonContainer>
                        <ViewFileButton
                          onClick={() =>
                            handleViewFile(
                              {
                                filePath: getBeforeSecondSlash(
                                  file.fileLocation,
                                ),
                                bucketName: file.bucketName,
                              },
                              file.fileName,
                            )
                          }
                        >
                          <ViewIcon />
                        </ViewFileButton>
                        <DeleteButton
                          onClick={() =>
                            deleteDocument(
                              file_info,
                              file.fileLocation,
                              account_id,
                            )
                          }
                        >
                          <DeleteIcon />
                        </DeleteButton>
                      </FunctionButtonContainer>
                    </tr>
                  ));
                },
              )}
            {selectedTab === "complete" &&
              archivedDocuments.map(
                ({
                  account_id,
                  account_name,
                  file_info,
                  created_at,
                  idp_status,
                }) => {
                  // Parse file_info since it's a JSON string
                  const parsedFileInfo = JSON.parse(file_info);

                  return parsedFileInfo.map((file) => (
                    <tr key={`${account_id}-${file.fileId || file}`}>
                      <StyledTd>{account_id}</StyledTd>
                      <StyledTd>{account_name}</StyledTd>
                      <StyledTd
                        onClick={() =>
                          handleViewFile(
                            {
                              filePath: getBeforeSecondSlash(file.fileLocation),
                              bucketName: file.bucketName,
                            },
                            file.fileName || file,
                          )
                        }
                      >
                        {file.fileName || file}
                      </StyledTd>
                      <StyledTd>
                        {new Date(created_at).toLocaleString()}
                      </StyledTd>
                      <StyledTd>
                        <StatusSpan status={idp_status}>
                          {idp_status}
                        </StatusSpan>
                      </StyledTd>
                      <FunctionButtonContainer>
                        <ViewFileButton
                          onClick={() =>
                            handleViewFile(
                              {
                                filePath: getBeforeSecondSlash(
                                  file.fileLocation,
                                ),
                                bucketName: file.bucketName,
                              },
                              file.fileName || file,
                            )
                          }
                        >
                          <ViewIcon />
                        </ViewFileButton>
                        <DeleteButton
                          onClick={deleteDocument(
                            file_info,
                            file.fileLocation,
                            account_id,
                          )}
                        >
                          <DeleteIcon />
                        </DeleteButton>
                      </FunctionButtonContainer>
                    </tr>
                  ));
                },
              )}
          </StyledTable>
        </VerticalContainer>
      </ScreenRoot>
      <Popup
        dialogContent={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              padding: "0",
            }}
          >
            <div style={{ flex: "0 0 auto" }}>
              <CloseButton
                handleClose={() => {
                  setPdf({ fileName: "", data: null });
                  setOpenPdfViewer(false);
                }}
              />
              <MediumText>{pdf.fileName}</MediumText>
            </div>
            <div style={{ flex: "1 1 auto", overflow: "hidden" }}>
              <iframe
                src={pdf.data}
                style={{ width: "100%", height: "596px", border: "none" }}
                title={pdf.fileName}
              />
            </div>
          </div>
        }
        height={"732px"}
        innerHeight={"732px"}
        innerPadding={"28px"}
        margin={"0"}
        open={openPdfViewer}
        setOpen={setOpenPdfViewer}
        width={"690px"}
      />
    </>
  );
}
