import InsightsIcon from "../../../assets/dashboardIcons/InsightsIcon";
import {FunctionalButton} from "../../../components/functionalButton";
import React from "react";
import { MediumText, RegularText, StyledBox, SmallText } from "../styles";
import { useHistory } from "react-router";

import {
    FunctionArea,
    StyledTitle,
    IntroArea,
    ButtonContainer,
    ComingSoonImg,
    ImgDiv,
} from "./styles";
import { IDPIcon } from "assets/dashboardIcons/idpIcons";

export const IDP = ({ isMobile }) => {
    const history = useHistory();

    const handleIDP = () => {
        history.push("/underwriter/idpdocuments");
    }

    return (
        <FunctionArea isMobile={isMobile}>
            {isMobile && (
                <StyledBox
                    boxHeight="110px"
                    borderRadius="15px"
                    isMobile={isMobile}
                    onClick={handleIDP}
                    style={{ cursor: "pointer" }}
                >
                    <IntroArea>
                        <IDPIcon />
                    </IntroArea>
                    <ButtonContainer>
                        <FunctionalButton
                            buttonTitle="IDP Documents"
                            width="80%"
                            marginRight="0px"
                            isReversedColor={true}
                            hasBoxShadow={true}
                            handleButton={handleIDP}
                            disabled={false}
                        />
                    </ButtonContainer>
                </StyledBox>
            )}
            {!isMobile && (
                <>
                    <MediumText>IDP Documents</MediumText>
                    <StyledBox boxHeight="216px">
                        <IntroArea>
                            <IDPIcon/>
                        </IntroArea>
                        <ButtonContainer>
                            <FunctionalButton
                                buttonTitle="View IDP Documents"
                                width="80%"
                                marginRight="0px"
                                isReversedColor={true}
                                hasBoxShadow={true}
                                handleButton={handleIDP}
                                disabled={false}
                            />
                        </ButtonContainer>
                    </StyledBox>
                </>
            )}
        </FunctionArea>
    )
}
