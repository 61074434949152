import { HorizontalContainer, ScreenRoot, VerticalContainer } from "../styles";
import { useMediaQuery } from "react-responsive";
import { StageList } from "../uploader";
import React, { useEffect, useRef, useState } from "react";
import { LargeText, MediumText } from "../../styles";
import { ProceedButtonContainer, TitleContainer } from "../uploader/styles";
import { FunctionalButton } from "../../../../components/functionalButton";
import { Document, Page } from "react-pdf";
import {
  AutoConfirmButton,
  ClassificationSkeleton,
  ClassifiedNameSelect,
  CustomRadio,
  RadioWrapper,
} from "./styles";
import {
  editClassifierData,
  getClassifierData,
  getPdfDocsFromBucket,
} from "../../../../api/external_data";
import { useHistory, useLocation } from "react-router";
import "react-loading-skeleton/dist/skeleton.css";
import {
  getUnderwriterIdpActiveDocuments,
  getUnderwriterIdpArchivedDocuments,
} from "../../../../data/actions/underwriters";
import { useDispatch } from "react-redux";

export default function Classifier() {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const history = useHistory();

  const dispatch = useDispatch();

  const location = useLocation();

  const { selectedAccount, clientDocumentsId, splitterFiles } =
    location.state || {};

  const autoConfirmThresholdSelector = useRef();
  const [splitData, setSplitData] = useState([]);
  const [splits, setSplits] = useState([]);
  const [autoConfirmEnabled, setAutoConfirmEnabled] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [activeDocument, setActiveDocument] = useState();
  const isPollingInProgress = useRef(false);
  const [previousResponse, setPreviousResponse] = useState([]);

  const previousIdsRef = useRef(new Set());

  useEffect(() => {
    if (splitterFiles) {
      console.log("getting docs ", splitterFiles);
      getSplitDocuments(splitterFiles);
    }
  }, []);

  useEffect(() => {
    isPollingInProgress.current = false;
    let pollingInterval;

    const accountDetails = {
      account_id: selectedAccount.AccountId,
      client_documents_id: clientDocumentsId,
    };

    const fetchClassifierData = async () => {
      if (isPollingInProgress.current) return;

      isPollingInProgress.current = true;

      try {
        await getClassifierData(
          accountDetails,
          (response) => {
            // If all splits are classified, stop polling
            console.log(
              "classifier polling condition, ",
              splitterFiles.length,
              response.length,
            );
            if (splitterFiles.length === response.length) {
              console.log(
                "stopped polling ",
                splitterFiles.length,
                response.length,
              );
              dispatch(getUnderwriterIdpActiveDocuments());
              dispatch(getUnderwriterIdpArchivedDocuments());
              clearInterval(pollingInterval);
            } else {
              isPollingInProgress.current = false;
            }
            // if new elements are still coming in
            const previousIds = new Set(
              previousResponse.map((oldElement) => {
                const oldId = oldElement.files[0].splitFiles[0].id;
                console.log("Previous ID:", oldId);
                return oldId;
              }),
            );

            // Filter response with logging
            const newElements = response.filter((newElement) => {
              const newId = newElement.files[0].splitFiles[0].id;
              const isNew = !previousIdsRef.current.has(newId);
              console.log("New ID:", newId);
              console.log(`Is ID ${newId} new?`, isNew);
              return isNew;
            });

            // Log the new elements
            console.log("New elements:", newElements);

            // If there are new elements, process them
            if (newElements.length > 0) {
              // Add new IDs to previousIdsRef
              newElements.forEach((element) => {
                const id = element.files[0].splitFiles[0].id;
                previousIdsRef.current.add(id);
              });

              // Update your state variables
              setPreviousResponse((prevResponse) => [
                ...prevResponse,
                ...newElements,
              ]);

              setSplitData((prevState) => [...prevState, ...newElements]);
              const parsedResponse = parseSplits(newElements);
              setSplits((prevState) => [...prevState, ...parsedResponse]);
            }
          },
          (error) => {
            console.log(`Failed to get classifier data: ${error}`);
          },
        );
      } catch (e) {
        console.error("Error in fetchClassifierData", e);
      }
    };

    pollingInterval = setInterval(fetchClassifierData, 1000);

    fetchClassifierData();

    return () => clearInterval(pollingInterval);
  }, []);

  console.log("splitData ", splitData);
  console.log("splits ", splits);

  useEffect(() => {
    console.log("docs ", documents);
  }, [documents]);

  const handleProceed = async () => {
    console.log("splts", splits);
    const modelMappings = {
      P_AND_L: "profitAndLoss",
      CASHFLOW: "cashflow",
      BALANCE_SHEET: "balanceSheet",
      WIP: "workInProgress",
      WORK_COMPLETED: "workCompleted",
    };

    console.log("handleProceed - splitData ", splitData);

    const generateUpdatedClassifierData = () => {
      const mappedOutput = splitData
        .map((doc) => {
          // Map over the files in each document
          const splitFiles = doc.files.flatMap((file) => {
            return file.splitFiles
              .map((splitFile) => {
                const matchingSplit = splits.find(
                  (splitItem) => splitItem.splitId === splitFile.id,
                );

                if (!matchingSplit) {
                  console.log(
                    `No matching split found for splitId: ${splitFile.id}`,
                  );
                  return null;
                }

                const activeEntityType = matchingSplit.activeEntity?.type;
                if (activeEntityType && modelMappings[activeEntityType]) {
                  return {
                    id: splitFile.id,
                    bucketName: splitFile.bucketName,
                    filePath: splitFile.filePath,
                    fileName: splitFile.fileName,
                    labellerModel: modelMappings[activeEntityType],
                  };
                } else {
                  console.log(
                    `No valid modelMapping or activeEntity for splitId: ${splitFile.id}`,
                  );
                  return null;
                }
              })
              .filter((splitFile) => splitFile !== null); // Remove nulls
          });

          if (splitFiles.length > 0) {
            return {
              id: doc.files[0].id,
              bucketName: doc.files[0]?.bucketName || "",
              filePath: doc.files[0]?.filePath || "",
              fileName: doc.files[0]?.fileName || "",
              splitFiles: splitFiles,
            };
          }

          return null;
        })
        .filter((file) => file !== null);

      console.log("mapped output ", {
        id: splitData[0].id,
        files: [...mappedOutput],
      });

      return {
        id: splitData[0].id,
        files: [...mappedOutput],
      };
    };

    const updatedData = generateUpdatedClassifierData();

    const updatedClassificationData = {
      account_id: selectedAccount.AccountId,
      client_documents_id: clientDocumentsId,
      labeller_data: updatedData,
    };

    await editClassifierData(
      updatedClassificationData,
      (response) => {
        console.log("update data ", updatedData);
        // if (
        //   selectedAccount.ClassifierThreshold !==
        //   autoConfirmThresholdSelector.current.value
        // ) {
        //   updateClassifierThreshold(
        //     accountDetails,
        //     (response) => {
        //       console.log(
        //         "Successfully updated the classifier threshold value. ",
        //       );
        //     },
        //     (error) => {
        //       console.log(
        //         "Failed to update the classifier threshold value. ",
        //         error,
        //       );
        //     },
        //   );
        // }
        history.push({
          pathname: "/underwriter/idpdocuments/parser",
          state: {
            selectedAccount,
            clientDocumentsId,
            splitFilesCount: updatedData.files.length,
          },
        });
      },
      (error) => {
        console.log("error editing classifier data ", error);
      },
    );
  };

  const getSplitDocuments = (splitFiles) => {
    const pdfFiles = [];
    const promises = [];

    splitFiles.forEach((file) => {
      const promise = getPdfDocsFromBucket({
        bucketName: file.bucketName,
        filePath: file.filePath,
      }).then((fetchedBlobs) => {
        const matchingBlob = fetchedBlobs.find(
          (fetchedBlob) => fetchedBlob.fileName === file.fileName,
        );

        if (matchingBlob) {
          pdfFiles.push({
            fileName: file.fileName,
            blob: matchingBlob.blob,
            splitId: file.splitFileId,
          });
        } else {
          console.warn(`No matching blob found for ${file.fileName}`);
        }
      });

      promises.push(promise);
    });

    Promise.all(promises)
      .then(() => {
        setDocuments(pdfFiles);

        if (pdfFiles.length > 0) {
          const pdfBlob = new Blob([pdfFiles[0].blob], {
            type: "application/pdf",
          });

          // set right side viewer with first pdf blob
          const objectURL = URL.createObjectURL(pdfBlob);
          setActiveDocument(objectURL);
        }

        console.log("inside callback pdf files ", pdfFiles);
      })
      .catch((error) => {
        console.error("Error fetching documents:", error);
      });
  };

  const getDocuments = (res) => {
    const pdfFiles = [];
    const promises = [];

    res.forEach((file) => {
      file.files[0].splitFiles.forEach((splitFile) => {
        const promise = getPdfDocsFromBucket({
          bucketName: splitFile.bucketName,
          filePath: splitFile.filePath,
        }).then((fetchedBlobs) => {
          const matchingBlob = fetchedBlobs.find(
            (fetchedBlob) => fetchedBlob.fileName === splitFile.fileName,
          );

          if (matchingBlob) {
            pdfFiles.push({
              fileName: splitFile.fileName,
              blob: matchingBlob.blob,
              splitId: splitFile.id,
            });
          } else {
            console.warn(`No matching blob found for ${splitFile.fileName}`);
          }
        });

        promises.push(promise);
      });
    });

    Promise.all(promises)
      .then(() => {
        setDocuments((prevDocuments) => {
          // Filter to get only new documents that are not already present in the state
          const newDocuments = pdfFiles.filter(
            (newDoc) =>
              !prevDocuments.some(
                (prevDoc) => prevDoc.fileName === newDoc.fileName,
              ),
          );
          return [...prevDocuments, ...newDocuments];
        });

        if (pdfFiles.length > 0) {
          const pdfBlob = new Blob([pdfFiles[0].blob], {
            type: "application/pdf",
          });

          // set right side viewer with first pdf blob
          const objectURL = URL.createObjectURL(pdfBlob);
          setActiveDocument(objectURL);
        }

        console.log("inside callback pdf files ", pdfFiles);
      })
      .catch((error) => {
        console.error("Error fetching documents:", error);
      });
  };

  console.log("outside function pdf files ", documents);

  const parseSplits = (res) => {
    let results = [];

    res.forEach((file) => {
      console.log("parsedSplits added a file ", file);
      file.files[0].splitFiles.forEach((splitFile) => {
        console.log("parsedSplits added a splitFile ", splitFile);
        const entities = splitFile.classifierOutput.entities.map((entity) => ({
          id: entity.id,
          type: entity.type,
          confidence: entity.confidence,
          mentionText: entity.mentionText,
        }));

        const highestConfidenceEntity = entities.reduce(
          (maxEntity, entity) =>
            entity.confidence > (maxEntity?.confidence || 0)
              ? entity
              : maxEntity,
          null,
        );

        results.push({
          confirmed: false,
          splitId: splitFile.id, // Using splitFile id
          fileId: file.files[0].id, // Ensure this is the correct fileId
          filePath: splitFile.filePath,
          fileName: splitFile.fileName,
          entities: entities,
          activeEntity: highestConfidenceEntity || null,
        });
      });
    });

    console.log("parsed splits results ", results);

    console.log("results.length ", results.length);

    return results;
  };

  const toggleConfirm = (splitId) => {
    setSplits((prevSplits) =>
      prevSplits.map((split) =>
        split.splitId === splitId
          ? { ...split, confirmed: !split.confirmed } // Invert the confirmed value
          : split,
      ),
    );
  };

  const handleAutoConfirmed = () => {
    const threshold = autoConfirmThresholdSelector.current.value / 100;

    setSplits((prevSplits) =>
      prevSplits.map((split) => {
        if (split.activeEntity && split.activeEntity.confidence >= threshold) {
          return {
            ...split,
            confirmed: true,
          };
        }

        return split;
      }),
    );

    setAutoConfirmEnabled(true);
  };

  const handleAutoConfirmDisabled = () => {
    setSplits((prevSplits) =>
      prevSplits.map((split) => {
        return {
          ...split,
          confirmed: false,
        };
      }),
    );

    setAutoConfirmEnabled(false);
  };

  const handleClassifiedNameChange = (splitId, classifiedNameId) => {
    setSplits((prevSplits) => {
      return prevSplits.map((split) => {
        if (split.splitId === splitId) {
          const matchingEntity = split.entities.find(
            (entity) => entity.id === classifiedNameId,
          );

          return {
            ...split,
            activeEntity: matchingEntity,
          };
        }

        return split;
      });
    });
  };

  const setActiveSplit = (splitId) => {
    const activeDoc = documents.find((doc) => doc.splitId == splitId);

    if (activeDoc) {
      const pdfBlob = new Blob([activeDoc.blob], { type: "application/pdf" });
      const objectURL = URL.createObjectURL(pdfBlob);
      setActiveDocument(objectURL);
    } else {
      console.warn(`No document found with splitId: ${splitId}`);
    }
  };

  return (
    <>
      <ScreenRoot isMobile={isMobile}>
        <VerticalContainer>
          <HorizontalContainer>
            <TitleContainer>
              <LargeText>{selectedAccount.CompanyName}</LargeText>
              <MediumText>{selectedAccount.AccountId}</MediumText>
            </TitleContainer>
            <StageList
              stages={["Uploader", "Splitter", "Classifier", "Parser"]}
              currentStage="Classifier"
            />
            <ProceedButtonContainer>
              <FunctionalButton
                buttonTitle="Proceed"
                handleButton={handleProceed}
                width="100%"
                height="48px"
                isReversedColor={false}
                marginRight={"48px"}
                marginLeft={"48px"}
                marginTop={"48px"}
                marginBottom={"48px"}
                fontSize={"16px"}
                disabled={
                  !documents.length > 0 ||
                  !splits.every((split) => split.confirmed)
                }
                icon={null}
                hasBoxShadow={true}
                frontIcon={null}
                weight={600}
              />
            </ProceedButtonContainer>
          </HorizontalContainer>
          <HorizontalContainer>
            <VerticalContainer
              style={{
                maxHeight: "698px",
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  padding: "15px",
                }}
              >
                <input
                  ref={autoConfirmThresholdSelector}
                  defaultValue={selectedAccount.ClassifierThreshold}
                  type="number"
                  min="0"
                  max="100"
                />
                <AutoConfirmButton
                  enabled={autoConfirmEnabled}
                  onClick={() =>
                    autoConfirmEnabled
                      ? handleAutoConfirmDisabled()
                      : handleAutoConfirmed()
                  }
                >
                  Auto Confirm
                </AutoConfirmButton>
              </div>
              {documents.length > 0 ? (
                (() => {
                  const processedFiles = new Set();
                  console.log("split data length", splitData.length);
                  return splitData.map((data) => {
                    return data.files.map((file) => {
                      if (!processedFiles.has(file.id)) {
                        processedFiles.add(file.id);

                        const relatedSplits = splits.filter(
                          (split) => split.fileId === file.id,
                        );

                        if (relatedSplits.length > 0) {
                          return (
                            <div key={file.id} style={{ padding: "30px 0" }}>
                              <MediumText>{file.fileName}</MediumText>

                              {relatedSplits.map((split) => (
                                <div
                                  key={split.splitId}
                                  style={{ marginBottom: "8px" }}
                                >
                                  <ClassifiedSplit
                                    onClick={() =>
                                      setActiveSplit(split.splitId)
                                    }
                                    split={split}
                                    document={documents.find(
                                      (document) =>
                                        split.splitId === document.splitId,
                                    )}
                                    confirmed={split.confirmed}
                                    toggleConfirm={toggleConfirm}
                                    onClassifiedNameChange={
                                      handleClassifiedNameChange
                                    }
                                    splitSelect={setActiveSplit}
                                  />
                                </div>
                              ))}
                            </div>
                          );
                        } else {
                          return null;
                        }
                      } else {
                        return null;
                      }
                    });
                  });
                })()
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <ClassificationSkeleton></ClassificationSkeleton>
                  <ClassificationSkeleton></ClassificationSkeleton>
                  <ClassificationSkeleton></ClassificationSkeleton>
                  <ClassificationSkeleton></ClassificationSkeleton>
                  <ClassificationSkeleton></ClassificationSkeleton>
                  <ClassificationSkeleton></ClassificationSkeleton>
                  <ClassificationSkeleton></ClassificationSkeleton>
                </div>
              )}
            </VerticalContainer>
            <VerticalContainer>
              {documents && (
                <div
                  style={{
                    margin: "0 72px",
                    width: "100%",
                    minHeight: "700px",
                  }}
                >
                  {" "}
                  {/* Make the container div take up the full width and height */}
                  <iframe
                    src={activeDocument}
                    type="application/pdf"
                    width="100%" // Allow iframe to take up the full width of the container
                    height="100%" // Allow iframe to take up the full height of the container
                    title="PDF Viewer"
                    style={{
                      overflow: "auto", // Enable scrolling for both axes
                      whiteSpace: "nowrap",
                      border: "none",
                    }}
                  />
                </div>
              )}
            </VerticalContainer>
          </HorizontalContainer>
        </VerticalContainer>
      </ScreenRoot>
    </>
  );
}

const ClassifiedSplit = ({
  split,
  document,
  confirmed,
  toggleConfirm,
  onClassifiedNameChange,
  splitSelect,
}) => {
  const [pageCount, setPageCount] = useState();

  const onLoadSuccess = ({ numPages }) => {
    setPageCount(numPages);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: "70px",
        boxShadow: "0px 2px 6px 0px #0000000F",
        backgroundColor: "white",
        cursor: "pointer",
      }}
    >
      {/*Page thumbnail and classified name dropdown*/}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <div
          onClick={() => splitSelect(split.splitId)}
          style={{
            padding: "5px 20px",
            backgroundColor: "#EBE9F1BF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Document file={document.blob} onLoadSuccess={onLoadSuccess}>
            <Page
              pageNumber={1}
              width="38"
              height="48"
              renderAnnotationLayer={false}
            />
          </Document>
          <span style={{ fontSize: "10px" }}>
            {pageCount} {pageCount > 1 ? "pages" : "page"}
          </span>
        </div>
        {/*Dropdown with names*/}
        <div>
          <ClassifiedNameSelect
            onChange={(e) =>
              onClassifiedNameChange(split.splitId, e.target.value)
            }
          >
            {split.entities
              .sort((a, b) => b.confidence - a.confidence)
              .map((entity) => {
                return (
                  <option key={entity.id} value={entity.id}>
                    {entity.type} - {(entity.confidence * 100).toFixed(2)}%
                  </option>
                );
              })}
          </ClassifiedNameSelect>
        </div>
      </div>
      {/*confirm radio button*/}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "25px",
        }}
      >
        <RadioWrapper>
          <CustomRadio
            name="confirm-radio"
            onClick={() => toggleConfirm(split.splitId)}
            confirmed={confirmed}
          />
        </RadioWrapper>
      </div>
    </div>
  );
};
