export const IDPIcon = () => (
    <svg width="103" height="102" viewBox="0 0 103 102" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_676_571)">
            <rect x="8.75" y="4" width="86" height="86" rx="16" fill="#95D394" shape-rendering="crispEdges"/>
            <g clip-path="url(#clip0_676_571)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27.9308 20.1278C27.8898 20.2875 27.8679 20.4549 27.8679 20.6274C27.8679 20.7885 27.887 20.9452 27.9229 21.0952V22.2273V23.0856V23.1127V59.1187C27.9229 59.872 28.3463 60.5615 29.0181 60.9023L57.1118 75.1539C58.4422 75.8288 60.0166 74.8621 60.0166 73.3702V63.6274H71.632C73.8411 63.6274 75.632 61.8366 75.632 59.6274V22.6274C75.632 20.4183 73.8411 18.6274 71.632 18.6274H29.8679C29.1105 18.6274 28.4515 19.0484 28.112 19.6692C28.0171 19.7994 27.9524 19.9549 27.9308 20.1278ZM35.808 22.6274L58.9214 34.3526C59.5932 34.6934 60.0166 35.3828 60.0166 36.1362V59.6274H71.632V22.6274H35.808ZM31.9229 57.8906V25.1418L56.0166 37.3642V70.113L31.9229 57.8906Z" fill="white"/>
            </g>
        </g>
        <defs>
            <filter id="filter0_d_676_571" x="0.75" y="0" width="102" height="102" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="4"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_676_571"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_676_571" result="shape"/>
            </filter>
            <clipPath id="clip0_676_571">
                <rect width="56" height="56" fill="white" transform="translate(23.75 19)"/>
            </clipPath>
        </defs>
    </svg>
)